@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --text-primary: "#101828";
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --text-primary: "#101828"
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    
    h1, h2, h3, h4, p {
      margin: 0px;
    }
  }
}


@layer utilities {
  .heroTextGradient {
    background: linear-gradient(-45deg, rgb(21, 112, 239), rgb(83, 177, 253), rgb(21, 112, 239)) 0 0 / 200% 100%;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
  }

  .hero-text {
    @apply
      text-[28px] leading-120 font-semibold -tracking-[0.72px]
  }

  .hero-font {
    font-size: calc(12px + 3.20625vw);
  }

  .hero-text-dsktp {
    @apply text-hero leading-125 -tracking-[1.44px]
  }

  .layout-border {
    @apply border-layoutBorder
  }

  .line-clamp {
    overflow: hidden !important;
    white-space: pre-wrap !important;
    text-overflow: ellipsis !important;
    line-clamp: 2 !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    -moz-line-clamp: 2 !important;
  }

  .final-section-image {
    @apply bg-gradient-to-b;
    background: linear-gradient(180deg, rgba(9, 11, 22, 0) 0%, rgba(255, 255, 255, 0.08) 100%), rgba(255, 255, 255, 0.02);
  }

  .footerTopBorder {
    border-bottom: 3px solid transparent; /* Set border thickness */
    border-image: linear-gradient(90deg, #0D0E14 7.12%, #2E90FA 50.09%, #0D0E14 93.06%);
    border-image-slice: 1;
  }

  .smbr {
    @apply md:hidden
  }

  .mdxlBr {
    @apply hidden md:block
  }

  .brinxl {
    @apply hidden xl:block
  }

  .brinmd {
    @apply hidden md:block xl:hidden
  }

  .bldinbtwn {
    @apply text-txtP;
    font-weight: 500;
  }

  .blogSctionHeader {
    @apply
      mb-6 text-tlg leading-150 !text-txtP
      md:text-[calc(20px+1.9456vw)] lg:text-txl xl:leading-135
  }

  .blogSctionContet {
    @apply
      text-tsm leading-140 !text-txtS
      md:text-[calc(12px+1.9456vw)] md:leading-150
      xl:text-tmd xl:leading-[155%]
  }

  .mb32_40 {
    @apply mb-8 xl:mb-10
  }

  .blogLi {
    @apply
      text-tsm leading-140 !text-txtS
      md:text-[calc(12px+1.9456vw)] md:leading-150
      xl:text-tmd xl:leading-[155%]
  }

  .fmd {
    @apply font-medium
  }
}